<template>
  <b-card>
      <b-tabs align="left" class="tab-primary" v-model="tabIndex">
        <b-tab>
          <template #title>
            <div class="tab-title">
              <feather-icon icon="SendIcon" />
              <span> {{$t('Envíos')}} </span>
            </div>
          </template>
          <printer-labels></printer-labels>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div>
              <feather-icon icon="FileTextIcon" />
              <span> {{$t('Manifiestos')}} </span>
            </div>
          </template>
          <list-summary></list-summary>
        </b-tab>
      </b-tabs>
    </b-card>
</template>

<script>
import PrinterLabels from './PrinterLabels.vue'
import ListSummary from './ListSummary.vue'
export default {
  components: { ListSummary, PrinterLabels },
  data() {
    return {
      tabIndex: 0
    }
  }
}
</script>

<style>
</style>